.desktop_root{
    background: transparent;
}

.desktop_root_inner{
    overflow: auto;
    border-radius: 12px;
    //border: 1px solid #a6acb1;
    width: 100%;
    height: fit-content;
    //box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;
}

.language_div{
    width: fit-content;
    height: 40px;
    border-radius: 8px;
    //border: 1px solid red;
}

.vertical_align{
    vertical-align: middle;
    display: table-cell;
    height: 100%;
    z-index: -10;
    width: 100%;
}

.switch_icon{
    height: 15px;
    width: 15px;
}

.switch_text_div{
    
    width: 50%;
    text-align: center;
    align-items: center;
    display: table;
}

.switch_text{
    color: white;
    font-size: 13px;
}

.switch_icon_text{
    font-size: 10px;
}

.switch_div{

    width: 100%;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    display: table;
    //border: 1px solid green;
}

.switch{

    width: 100%;
    margin: 0 auto;
}

.switch_style{
    border: 0.5px solid var(--primary_colour);
    -webkit-box-shadow:inset 0px 0px 0px 10px var(--primary_colour);
    -moz-box-shadow:inset 0px 0px 0px 10px var(--primary_colour);
    box-shadow:inset 0px 0px 0px 10px var(--primary_colour);
}